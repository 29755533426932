// Third party libs/components
import React, { Fragment, useState } from 'react';
import Style from './style.module.css';
import { parsePhoneNumber } from 'react-phone-number-input';

import SEO from '@components/seo';
import HeaderProfile from '@components/HeaderProfile';
import TabSection from '@components/TabSection';
import ProfileForm from '@components/ProfileForm';
import ProfileNotification from '@components/ProfileNotification';
import ManageCard from '@components/ManageCard';

// Services
import {
  logout,
  getUser,
  handleUpdateUser,
  getHomeSpots,
} from '@services/auth';

// Utils
import { translate as t } from '@utils/translate';

const fields = [
  'fullName',
  'emailAddress',
  'documentTypeId',
  'birthdayDate',
  'mobileNumber',
  'emergencyPhoneNumber',
  'identityDocument',
  'isoCountryCodeMobileNumber',
  'isoCountryCodeEmergencyPhoneNumber',
];

const getPlace = () => {
  const homeSpots = getHomeSpots();
  if (homeSpots && homeSpots.length) return homeSpots[0].spot.name;
  return null;
};

const Profile = () => {
  const [user, setUser] = useState(getUser());
  const [loading, setLoading] = useState(false);
  const [activeSubmit, setActiveSubmit] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const TEXTS = {
    MY_ACCOUNT: t('myAccout'),
    MANAGE_ACCOUNT: t('manageAccount'),
    MANAGE_CARDS: t('manageCards'),
    LOGOUT_ACCOUNT: t('logoutAccount'),
    NOTIFICATIONS: t('notifications'),
    INVALID_PHONE: t('invalidPhone'),
  };

  const place = getPlace();

  const handleChangeProfile = () => {
    setActiveSubmit(true);
  };

  const handleSubmitProfile = data => {
    const new_user = Object.assign({}, user);
    fields.forEach(field => {
      new_user[field] = data[field];
    });

    if (new_user['birthdayDate']) {
      const parts = new_user['birthdayDate'].split('/');
      new_user['birthdayDate'] = parts[2] + '-' + parts[1] + '-' + parts[0];
    }

    if (data['birthCountry']) {
      new_user['countryOfBirth'] = {
        iso2Code: data['birthCountry'],
      };
    }

    if (data['mobileNumber']) {
      const number = parsePhoneNumber(new_user.mobileNumber);
      if (!number?.country) {
        setErrorMsg(TEXTS.INVALID_PHONE);
        return;
      }
      new_user.isoCountryCodeMobileNumber = number.country;
    }

    if (data['emergencyPhoneNumber']?.length) {
      const number = parsePhoneNumber(new_user.emergencyPhoneNumber);
      if (!number?.country) {
        setErrorMsg(TEXTS.INVALID_PHONE);
        return;
      }
      new_user.isoCountryCodeEmergencyPhoneNumber = number.country;
    }

    if (!data['emergencyPhoneNumber']) {
      new_user.emergencyPhoneNumber = '';
    }

    delete new_user['updateDatetime'];
    delete new_user['createDatetime'];
    setUser(new_user);
    handleUpdateUser(setLoading, setErrorMsg, new_user);
  };

  const handleChangeAvatar = file => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.addEventListener(
      'load',
      () => {
        const new_user = Object.assign({}, user);
        new_user['profilePicture'] = {
          type: file.type,
          content: reader.result.replace('data:' + file.type + ';base64,', ''),
        };
        delete new_user['updateDatetime'];
        delete new_user['createDatetime'];
        setUser(new_user);
        setActiveSubmit(true);
      },
      false
    );
  };

  return (
    <Fragment>
      <SEO yoast_head={'<title>' + TEXTS.MY_ACCOUNT + '</title>'} />
      <div className={[Style.content, 'container'].join(' ')}>
        <div className={Style.header}>
          <HeaderProfile
            avatarSrc={user.imageUrl}
            name={user.fullName}
            place={place}
            onChangeAvatar={handleChangeAvatar}
          />
        </div>
        <TabSection
          sections={[
            {
              title: TEXTS.MANAGE_ACCOUNT,
              content: (
                <ProfileForm
                  onSubmit={handleSubmitProfile}
                  onChange={handleChangeProfile}
                  loading={loading}
                  activeSubmit={activeSubmit}
                  errorMsg={errorMsg}
                  hasDelete
                />
              ),
            },
            {
              title: TEXTS.NOTIFICATIONS,
              content: <ProfileNotification />,
            },
            {
              title: TEXTS.MANAGE_CARDS,
              content: <ManageCard />,
              test: 'profile.manageCard',
            },
            { title: TEXTS.LOGOUT_ACCOUNT, onClick: logout },
          ]}
        />
      </div>
    </Fragment>
  );
};

export default Profile;
