import styled, { css } from 'styled-components';

export const SearchResultsHeaderWrapper = styled.header`
  background-color: #00897b;
  padding-top: 21px;
  z-index: 11;
  position: fixed;
  width: 100%;
  top: 0;
  height: var(--search-results-header-height);

  .openMenuModal {
    ${({ menuOpened }) => {
      return menuOpened
        ? css`
            visibility: hidden;
          `
        : '';
    }}
  }

  @media (max-width: 1350px) {
    padding-top: 14px;
  }
`;

export const SearchResultsHeaderContainer = styled.div`
  position: relative;
  height: 100%;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 36px;

  @media (max-width: 1350px) {
    margin-bottom: 32px;
  }
`;

export const BackButtonContainer = styled.div`
  flex-basis: 33%;
  color: #000;
  filter: invert(1);

  @media (max-width: 1350px) {
    flex-basis: 40px;

    > div {
      font-size: 0;
    }
  }
`;

export const LocationsDisplay = styled.div`
  flex-basis: 34%;
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
`;

export const Origin = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-end;
`;

export const OriginIcon = styled.i`
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 3px solid #80cbc4;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
`;

export const HideOverflowText = styled.div`
  white-space: nowrap;
  position: relative;
  height: 1.3em;
  overflow-x: hidden;
  flex-basis: 160px;

  > span {
    display: inline-block;
    min-width: 100%;
    position: absolute;
    transition: left 0.5s linear, text-indent 0.5s linear;
    left: 0;
    text-indent: 0;
  }

  &:after {
    content: '';
    width: calc(100% + 20px);
    height: 100%;
    background-image: linear-gradient(to left, #00897b 0, #00897b00 20px);
    position: absolute;
    left: -20px;
    transition: left 0.5s linear;
  }

  &:hover:after {
    left: 0;
  }

  &:hover > span {
    left: 100%;
    text-indent: -100%;
  }

  @media (max-width: 1350px) {
    flex-basis: 40px;

    &:after {
      display: none;
    }
  }
`;

export const LocationName = styled.span`
  @media (max-width: 1350px) {
    display: none;
  }
`;

export const SwapLocations = styled.div`
  flex-basis: 48px;
  border: 1px solid #fff;
  border-top-color: transparent;
  border-radius: 0 0 24px 24px;
  text-align: center;
  height: 37px;
  margin: 0 11px;
  cursor: pointer;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -2px;
    width: calc(100% + 4px);
    height: 10px;
    background-image: linear-gradient(to bottom, #00897b, #00897b00);
  }
`;

export const SwapIcon = styled.i``;

export const Destination = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 0;
  flex-grow: 1;
  justify-content: flex-start;
`;

export const DestinationIcon = styled.i`
  vertical-align: middle;
  margin-right: 10px;
`;

// FILTERS -------------------------
export const FiltersContainer = styled.div`
  display: flex;

  @media (max-width: 1350px) {
    position: absolute;
    background-color: #fff;
    top: 100%;
    left: calc(-1 * var(--container-size));
    width: 100vw;
    padding: 21px var(--container-size);
    box-shadow: 0px 10px 15px #0000004d;
    display: none;
    max-height: calc(100vh - var(--search-results-header-height));
    overflow-y: auto;

    ${({ opened }) =>
      opened &&
      css`
        display: block;
      `}
  }
`;

export const LeftFiltersContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 1350px) {
    display: block;
  }
`;

export const RightFiltersContainer = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 1350px) {
    display: block;
  }
`;

export const LogoWrapper = styled.div`
  flex-basis: 105px;
  flex-grow: 0;

  > svg {
    max-width: 100%;
  }

  @media (max-width: 1350px) {
    display: none;
  }
`;

export const Logo = styled.div``;

export const DateDisplay = styled.div`
  position: relative;
  flex-basis: calc(33% - 40px);

  @media (max-width: 1350px) {
    position: absolute;
    left: calc(-1 * var(--container-size));
    top: 100%;
    background-color: #00897b;
    width: 100vw;
    padding: 21px var(--container-size);
    justify-content: flex-start;
    display: none;

    ${({ dateOpened }) => {
      return dateOpened
        ? css`
            display: flex;
          `
        : '';
    }}
  }

  @media (max-width: 1439px) {
    padding-left: 12px;
  }
`;

export const DateDisplayContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: auto;

  padding: 5px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.4s ease-out;

  &:hover {
    background-color: #1eb19d;
  }
`;

export const Month = styled.div`
  text-transform: capitalize;
`;

export const Day = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 34px;
  border: 2px solid #31a297;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  text-align: center;
  margin: -2px 11px 0;

  @media (max-width: 1439px) {
    margin: -2px 7px 0;
  }
`;

export const ToLabel = styled.div`
  color: #7ec9c2;
`;

export const OpenMenuModal = styled.div`
  flex-basis: 40px;
  text-align: right;
  cursor: pointer;

  svg {
    transform-origin: right;
    transition: transform 0.2s ease-out;
  }

  &:hover svg {
    transform: scaleX(1.2);
  }
`;

export const Bottom = styled.div``;

export const MobileBottom = styled.div`
  display: none;

  @media (max-width: 1350px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ToggleDate = styled.div`
  @media (max-width: 1350px) {
    font-size: 15px;
    font-weight: 600;
    color: #fff;

    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #7ec9c2;
      border-bottom-width: 2px;
      margin-left: 8px;
    }
  }
`;

export const MobileLogoContainer = styled.div`
  @media (max-width: 1350px) {
    flex-basis: 65px;
    flex-grow: 0;
    height: 24px;

    svg {
      max-width: 100%;
      height: 100%;
    }
  }
`;

export const MobileLogo = styled.div`
  @media (max-width: 1350px) {
  }
`;

export const ToggleFilters = styled.div`
  @media (max-width: 1350px) {
    font-size: 15px;
    font-weight: 600;
    color: #fff;

    &:after {
      content: '';
      display: inline-block;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-top-color: #7ec9c2;
      border-bottom-width: 2px;
      margin-left: 8px;
    }
  }
`;
