import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
// import { navigate } from '@reach/router';
import { navigate } from 'gatsby';
import { Helmet } from 'react-helmet';
import Style from './style.module.css';
// import useFlapperApi from '../../utils/useFlapperApi';
import { useParams } from '@reach/router';
import { translate as t, useCurrentLang } from '@utils/translate';
import {
  apiHost,
  getFlightByIdRoute,
  getAircraftByIdRoute,
} from '../../utils/constants';
import { getProps } from './utils';
import BackButton from '@components/BackButton';
import FlightDisplay from '@components/FlightDisplay';
import FlightSchedule from '@components/FlightSchedule';
import AircraftGallery from '@components/AircraftGallery';
import AircraftDescription from '@components/AircraftDescription';
import AircraftSecurity from '@components/AircraftSecurity';
import AircraftAbout from '@components/AircraftAbout';
import Amenities from '@components/Amenities';
import FlightInstructions from '@components/FlightInstructions';
import FlightInquire from '@components/FlightInquire';
import SectionFlightPolicies from '@components/SectionFlightPolicies';
import LoginBody from '@components/LoginBody';
import Modal from '@components/Modal';

import SpinningWheel from '@components/SpinningWheel';
import Button from '@components/Button';

import { isLoggedIn } from '@services/auth';
import createCheckout from '@services/createCheckout';
import { fetchSharedFlights } from '@services/search';
import { decodeObjectToken } from '@utils/token';
import { flightSearchSecret } from '@utils/secrets';
import useRequest from '@utils/useRequest';
import { formatDateString } from '@utils/dates';
import { getShortLink } from '@services/getShortLink';

import shareImage from '@images/newsletter-crop1.jpg';

const PageComponent = props => {
  const [hasBackdrop, setHasBackdrop] = useState(false);
  const [copyButtonLoading, setCopyButtonLoading] = useState(false);
  const [copyButtonLabel, setCopyButtonLabel] = useState(t('copyLink'));
  const seats = useSelector(state => state.cart.seats);
  const currency = useSelector(state => state.general.currency);

  const goToCheckout = (seats, flightId) => {
    if (isLoggedIn()) {
      const dataArray = [
        {
          typeId: 2,
          action: 'add',
          data: {
            seats,
            flight: {
              id: props.flightsSummary.destinationFlightId
                ? props.flightsSummary.originFlightId
                : flightId,
            },
          },
        },
      ];

      if (props.flightsSummary.destinationFlightId) {
        dataArray.push({
          typeId: 2,
          action: 'add',
          data: {
            seats,
            flight: {
              id: props.flightsSummary.destinationFlightId,
            },
          },
        });
      }

      createCheckout(null, dataArray, currency.code)
        .then(cartId => {
          navigate(`/checkout/${cartId}`);
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      setMustLogIn(true);
    }
  };

  const [step, setStep] = useState({
    step: 0,
    userCode: null,
    contactPhone: null,
  });
  const [mustLogIn, setMustLogIn] = useState(false);
  const [flightPrice, setFlightPrice] = useState(0);

  const TEXTS = {
    FORGOT_PASSWORD: t('forgotPassword'),
    BACK_BUTTON: t('back'),
    SCHEDULE_TITLE: t('scheduleTitle'),
    AMENITY_TITLE: t('amenityTitle'),
    BOARDING_iNSTRUCTIONS: t('boardingInstructions'),
    COPY_LINK: t('copyLink'),
    COPIED: t('copied'),
  };

  const changeStep = (step, userCode, contactPhone) => {
    setStep({ step, userCode, contactPhone });
  };

  const handleCloseLoginModal = () => {
    changeStep(0);
    setMustLogIn(false);
  };

  const handleWithLargeUrl = async () => {
    try {
      setCopyButtonLoading(true);
      const { link: shortLink } = await getShortLink(location.href);
      navigator.clipboard.writeText(shortLink);
      setCopyButtonLabel(TEXTS.COPIED + '!');

      setTimeout(() => {
        setCopyButtonLabel(TEXTS.COPY_LINK);
      }, 1500);
    } finally {
      setCopyButtonLoading(false);
    }
  };

  const onChangePrice = currentPrice => {
    setFlightPrice(currentPrice);
  };

  return (
    <>
      {/* <meta property="og:title" content="Flapper Flight" />
        <meta
          property="og:image"
          content={props.flightDisplay.destinationImage}
        />
        <meta property="og:description" content={props.flightSchedule.date} />
        <meta property="og:type" content="blog" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@FlyFlapper" />
        <meta name="twitter:title" content="Flapper Flight" />
        <meta name="twitter:description" content={props.flightSchedule.date} />
        <meta
          name="twitter:image"
          content={props.flightDisplay.destinationImage}
        />
        <meta name="twitter:alt" content={props.flightDisplay.destinationAlt} /> */}
      <Helmet
        meta={[
          {
            property: 'og:title',
            content: 'Flapper Flight',
          },
          {
            property: 'og:image',
            content: props.flightDisplay.destinationImage,
          },
          {
            property: 'og:description',
            content: props.flightSchedule.date,
          },
          {
            property: 'og:type',
            content: 'blog',
          },
          {
            name: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            name: 'twitter:site',
            content: '@FlyFlapper',
          },
          {
            name: 'twitter:title',
            content: 'Flapper Flight',
          },
          {
            name: 'twitter:description',
            content: props.flightSchedule.date,
          },
          {
            name: 'twitter:image',
            content: props.flightDisplay.destinationImage,
          },
          {
            name: 'twitter:alt',
            content: props.flightDisplay.destinationAlt,
          },
        ]}
        de
      />

      <Modal
        opened={mustLogIn}
        closeModal={handleCloseLoginModal}
        type={'center'}
        hasCloseButton={true}
      >
        <div className={Style.loginContainer}>
          <LoginBody
            logo={false}
            closeModal={() => goToCheckout(seats.filled, props.flightId)}
            step={step.step}
            userCode={step.userCode}
            contactPhone={step.contactPhone}
            setStep={(step, userCode, contactPhone) =>
              changeStep(step, userCode, contactPhone)
            }
            forgotPasswordMsg={TEXTS.FORGOT_PASSWORD}
          />
        </div>
      </Modal>
      <div
        className={[
          Style.backdropContainer,
          hasBackdrop ? Style.active : '',
        ].join(' ')}
      ></div>
      <div className={[Style.containerActions, 'container'].join(' ')}>
        <BackButton text={TEXTS.BACK_BUTTON} />
        <Button
          version="v2"
          label={copyButtonLabel}
          onClick={handleWithLargeUrl}
          className={Style.actionButton}
          loading={copyButtonLoading}
        />
      </div>
      <FlightDisplay {...props.flightDisplay} />
      <div
        className={['flightInquireContainer', 'container'].join(' ')}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <FlightSchedule
          title={TEXTS.SCHEDULE_TITLE}
          {...props.flightSchedule}
        />
        <FlightInquire
          {...props.flightInquire}
          show={!mustLogIn}
          handleHasBackdrop={setHasBackdrop}
          onGoToCheckout={goToCheckout}
          returningFlightsStats={props.returningFlightsStats}
          selectedOriginFlight={{
            id: props.flightsSummary.originFlightId,
            seatPrice: parseFloat(props.flightsSummary.originFlightPrice),
            originCurrency: props.flightsSummary.originFlightCurrency,
            path: props.flightsSummary.originFlightPath,
            returningPath: props.flightsSummary.destinationFlightPath,
          }}
          canChangeSeats={!props.flightsSummary.originFlightId}
          onChangePrice={onChangePrice}
        />
      </div>
      <AircraftGallery {...props.aircraftGallery} />
      <AircraftDescription {...props.aircraftDescription} />
      <AircraftSecurity {...props.aircraftSecurity} short={false} />
      <div
        className="container"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-between',
        }}
      >
        <AircraftAbout {...props.aircraftAbout} />
        <div className={Style.amenitiesWrapper}>
          <h2 className={Style.title}>{TEXTS.AMENITY_TITLE}</h2>
          <Amenities
            amenities={props.aircraftComfort.items}
            limitToShow={99}
            showTitle={false}
            breakLine={true}
            imgSize={3}
            theme={'green'}
          />
        </div>
      </div>
      <FlightInstructions
        sectionTitle={TEXTS.BOARDING_iNSTRUCTIONS}
        {...props.flightInstructions}
      />
      <div className={['container', Style.flightPolicies].join(' ')}>
        <SectionFlightPolicies
          cancelations={props.flightCancelation}
          totalPrice={flightPrice}
          currency={currency.code}
        />
      </div>
    </>
  );
};

const FlightDetail = ({ location, onMustLogIn, onLogIn }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [aircraftIsLoading, setAircraftIsLoading] = useState(true);
  const [aircraftIsError, setAircraftIsError] = useState(false);
  const { flightId, aircraftId } = useParams();
  const currency = useSelector(state => state.general.currency);
  const pageLanguage = useCurrentLang();
  const [flightEndpoint, setFlightEndpoint] = useState('');
  const [flightData, setFlightData] = useState(null);
  const [aircraftData, setAircraftData] = useState(null);
  const [originFlightId, setOriginFlightId] = useState(null);
  const [originFlightData, setOriginFlightData] = useState(null);
  const aircraft_endpoint = `${apiHost}${getAircraftByIdRoute}?id=${aircraftId}`;
  const reqOptions = { headers: { 'Accept-Language': pageLanguage } };
  const [spots, setSpots] = useState({});
  const [returningFlightsStats, setReturningFlightsStats] = useState([]);
  const [initialSelectedSeats, setInitialSelectedSeats] = useState(1);
  const [flightsDateTime, setFlightsDateTime] = useState({
    departureDate: null,
    departureTime: null,
    returnDate: null,
    returnTime: null,
  });
  const [tripType, setTripType] = useState('one-way');
  const [flightType, setFlightType] = useState('flights');
  const [flightsSummary, setFlightsSummary] = useState({
    originFlightId: null,
    originFlightPrice: 0,
    originFlightCurrency: 'BRL',
    destinationFlightId: null,
    destinationFlightPrice: 0,
  });

  const searchParams = {};

  /* const [
    {
      dataResponse: flightData,
      isLoading: flightIsLoading,
      isError: flightIsError,
    },
  ] = useFlapperApi(flightEndpoint, {}, reqOptions); */

  useEffect(() => {
    let searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('st');

    if (token) {
      const prevState = decodeObjectToken(token, flightSearchSecret);

      if (prevState) {
        try {
          setInitialSelectedSeats(prevState.selectedSeats);
          setTripType(prevState.tripType);
          setFlightType(prevState.flightType);
          setFlightsDateTime({ ...prevState.searchParams });
          setFlightsSummary({ ...prevState.flights });
          setOriginFlightId(prevState.flights.originFlightId);
          setDepartureDateTime();
          setReturnDateTime(prevState.searchParams.returnDate);
        } catch (error) {}
      }
    }
  }, []);

  useEffect(() => {
    if (
      flightData &&
      flightData.flight &&
      (flightData.flight.originSpot.address.city.id !== spots.originId ||
        flightData.flight.destinationSpot.address.city.id !==
          spots.destinationId)
    ) {
      const spots = {
        originId: flightData.flight.destinationSpot.address.city.id,
        destinationId: flightData.flight.originSpot.address.city.id,
      };
      setSpots(spots);
    }

    fecthData(
      aircraft_endpoint,
      setAircraftData,
      setAircraftIsLoading,
      setAircraftIsError
    );
  }, [flightData]);

  const prepareStatsParams = () => {
    searchParams.origin = { id: spots.originId };
    searchParams.destination = { id: spots.destinationId };

    if (flightsDateTime.departureDate && flightsDateTime.departureTime) {
      searchParams.minDatetime = formatDateString(
        decodeURIComponent(flightsDateTime.departureDate),
        decodeURIComponent(flightsDateTime.departureTime)
      );
    } else {
      const dateAndTime = flightData.flight.departureDatetime.split(' ');
      searchParams.minDatetime = formatDateString(
        dateAndTime[0],
        dateAndTime[1]
      );
    }

    if (flightsDateTime.returnDate && flightsDateTime.returnTime) {
      searchParams.maxDatetime =
        flightsDateTime.returnDate !== '-'
          ? formatDateString(
              decodeURIComponent(flightsDateTime.returnDate),
              decodeURIComponent(flightsDateTime.returnTime)
            )
          : formatDateString(
              decodeURIComponent(flightsDateTime.departureDate),
              '23:59'
            );
    }
  };

  const fecthData = async (
    url,
    setCallback,
    loadingCallback,
    errorCallback
  ) => {
    loadingCallback(true);
    const { data, status } = await useRequest({
      url,
      method: 'get',
      useSession: false,
    });

    if (status === 200) setCallback(data);
    else errorCallback(true);
    setTimeout(() => {
      loadingCallback(false);
    }, 500);
  };

  const handleFlightData = responseData => {
    setFlightData(responseData);
    const {
      flight: { type, isShared },
    } = responseData;

    if (type.name === 'EmptyLeg' && isShared == '0') setFlightType('empty-leg');
  };

  useMemo(() => {
    fecthData(
      `${apiHost}${getFlightByIdRoute}?id=${flightId}&seats=1&currency=${currency.code}`,
      handleFlightData,
      setIsLoading,
      setIsError
    );
  }, [currency]);

  useMemo(async () => {
    if (!originFlightId) {
      if (spots.originId && spots.destinationId) {
        prepareStatsParams();
        const responseDataStats = await fetchSharedFlights(searchParams, true);

        if (responseDataStats.status === 200)
          setReturningFlightsStats(responseDataStats.data.flightStats);
      }
    } else {
      fecthData(
        `${apiHost}${getFlightByIdRoute}?id=${originFlightId}&seats=1&currency=${currency.code}`,
        setOriginFlightData,
        setIsLoading,
        setIsError
      );
    }
  }, [spots, originFlightId]);

  useEffect(() => {
    if (originFlightData) {
      try {
        setFlightsSummary(prevState => {
          return {
            ...prevState,
            originFlightPrice: originFlightData.flight.seats[0].price,
            originFlightCurrency: currency.code,
          };
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [originFlightData]);

  if (isLoading || !flightData || !aircraftData) return <SpinningWheel />;

  const TEXTS = {
    GENERAL_LOADING: t('loading'),
    SOMETHING_WRONG: t('somethingWrong'),
  };

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {isLoading || aircraftIsLoading ? (
        <p
          style={{
            marginTop: 'calc(var(--header-height) + 20px)',
            marginBottom: '32px',
          }}
        >
          {' '}
          {TEXTS.GENERAL_LOADING}{' '}
        </p>
      ) : isError || aircraftIsError ? (
        <p
          style={{
            marginTop: 'calc(var(--header-height) + 20px)',
            marginBottom: '32px',
          }}
        >
          {TEXTS.SOMETHING_WRONG}
        </p>
      ) : (
        <>
          {flightData.flight && aircraftData.aircraft && (
            <PageComponent
              flightId={flightData.flight.id}
              returningFlightsStats={returningFlightsStats}
              onMustLogIn={onMustLogIn}
              onLogIn={onLogIn}
              flightsSummary={flightsSummary}
              {...getProps(
                {
                  ...flightData.flight,
                  selectedSeats: initialSelectedSeats,
                  flightsDateTime,
                  currency: currency.code,
                  tripType,
                  flightType,
                },
                aircraftData.aircraft,
                { language: pageLanguage }
              )}
            />
          )}
        </>
      )}
    </>
  );
};

export default FlightDetail;
