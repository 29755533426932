// Third party libs/components
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useParams, navigate } from '@reach/router';

// Components
import SearchResultsHeader from '@components/SearchResultsHeader';
import FlightResultSection from '@components/FlightResultSection';
import SharedFlight from '@components/SharedFlight';
import SearchLoading from './SearchLoading';
import SearchResultsAircraft from '../SearchResultsAircraft';

// Utils
import * as Helpers from './utils/Search.helper';
import * as Filters from './utils/filter';
import * as Sort from './utils/sort';
import { gtmDispatcher } from '@utils/gtm.helper';
import { translate as t } from '@utils/translate';
import { encodeObjectToken } from '@utils/token';
import { flightSearchSecret } from '@utils/secrets';

// Services
import * as Services from '@services/search';
import { analyticsGTAG } from '@utils/ga.helper';
import { getFilterValues } from './utils/filter/index';

const Search = ({ location }) => {
  const path = location.pathname;
  const [error, setError] = useState(false);
  const [searchResults, setSearchResults] = useState({});
  const [searchFilteredResults, setSearchFilteredResults] = useState([]);
  const [searchParams, setSearchParams] = useState();
  const [selectedModelId, setSelectedModelId] = useState();
  const routeParams = useParams();
  const store = useStore();
  const selectedCurrency = useSelector(state => state.general.currency);

  // Getting URL params
  Helpers.decodeParams(routeParams);

  routeParams.origin = Helpers.reduceLocationParams(
    routeParams.origin.split('&')
  );
  routeParams.destination = Helpers.reduceLocationParams(
    routeParams.destination.split('&')
  );

  const queryParams = new URLSearchParams(location.search);

  const aircraftModelId = queryParams.get('aircraftModelId');

  const fetchData = async () => {
    let responseData = null;

    if (routeParams.flightType === 'charter') {
      const body = {
        quotation: {
          currency: selectedCurrency.code,
          segments: [searchParams],
          aggregateBy: 'model',
        },
      };
      responseData = await Services.fetchCharteredFlights(body);
    } else {
      const range = Helpers.formatMinMaxDates({ ...routeParams });

      responseData = await Services.fetchSharedFlights({
        ...routeParams,
        ...range,
      });
    }

    if (responseData.status === 200) {
      responseData = Helpers.formatResponseForSearchResults(responseData.data);
      setSearchResults(responseData);
      setSearchFilteredResults(responseData);
    } else {
      setError(true);
    }

    return responseData;
  };

  const selectRoute = (aircraftID, routeID) => {
    analyticsGTAG('click', {
      event_category: 'Quotation',
      event_action: 'SelectAircraftModel',
      event_label: 'User select an aircraft model on search results',
    });

    gtmDispatcher('booking', {
      event_category: 'quotation',
      event_action: 'select_aircraft',
      event_label: 'User select an aircraft model on search results',
    });

    setSearchFilteredResults(prevState => {
      const findRouteByMappedIndex = r => {
        return r.mappedIndex === routeID;
      };

      const route = searchFilteredResults.routes.find(findRouteByMappedIndex);
      return {
        ...prevState,
        routes: [route],
        //routes: [searchResults.routes[routeID]],
      };
    });
    setSelectedModelId(aircraftID);
  };

  const handleBackAction = () => {
    if (selectedModelId) {
      setSearchFilteredResults(prevState => {
        return {
          ...prevState,
          routes: searchResults.routes,
        };
      });
      setSelectedModelId(null);
    } else {
      navigate('/');
    }
  };

  // Filter results
  const execFiltering = () => {
    let filteredData = [];
    filteredData = Filters.applyFilters(searchResults.routes);
    setSearchFilteredResults(prevState => {
      return {
        ...prevState,
        routes: filteredData,
      };
    });
  };

  // Sort results
  const execSortering = (sortMethodName, sortVariantName) => {
    let sortedData = [];
    sortedData = Sort.applySortering({
      methodName: sortMethodName,
      variantName: sortVariantName,
      data: searchFilteredResults.routes,
      flightType: routeParams.flightType,
    });
    setSearchFilteredResults(prevState => {
      return {
        ...prevState,
        routes: sortedData,
      };
    });
  };

  const handleSetFilterValue = (filterMethodName, values) => {
    Filters.updateFilterValues(filterMethodName, values);
    execFiltering();
  };

  useEffect(() => {
    setSearchParams(Helpers.prepareSearchParams(routeParams));
  }, []);

  const handleSelectAircraft = async aircraft => {
    const quotationParams = {
      ...Helpers.prepareSearchParams(routeParams, {
        originSpotId: searchFilteredResults.routes[0].originSpot.id,
        destinationSpotId: searchFilteredResults.routes[0].destinationSpot.id,
      }),
      aircraftId: aircraft.aircraft.id,
    };
    const body = {
      quotation: {
        currency: selectedCurrency.code,
        segments: [quotationParams],
        displayOnlyQuoted: true,
      },
    };
    let segmentDetails = {};

    let responseData = await Services.fetchCharteredFlights(body);
    responseData = Helpers.formatResponseForSearchResults(responseData.data);

    segmentDetails = {
      origin: {
        spotId: searchFilteredResults.routes[0].originSpot.id,
      },
      destination: {
        spotId: searchFilteredResults.routes[0].destinationSpot.id,
      },
      aircraftId: aircraft.aircraft.id,
      departureDatetime: searchFilteredResults.routes[0].departureDatetime,
      routes: [...responseData.routes],
    };
    setSelectedModelId(aircraft.aircraft.id);

    const token = encodeObjectToken([segmentDetails], flightSearchSecret);
    store.dispatch(Helpers.setQuotationsData([segmentDetails]));

    analyticsGTAG('click', {
      event_category: 'Quotation',
      event_action: 'Book',
      event_label: 'User click on the button book to select the aircraft',
    });

    gtmDispatcher('booking', {
      event_category: 'charter',
      event_action: 'book',
      event_label: 'User clicks to select an aircraft',
      value: aircraft.aircraft.id,
    });

    navigate(`/p/trip-detail?st=${token}`, { replace: false });
  };

  useMemo(() => {
    if (searchParams) fetchData();
  }, [searchParams, selectedCurrency]);

  useMemo(() => {
    // apply aircraftModelId filter here
    if (searchResults.routes) {
      Filters.initFilterValues({
        data: searchResults.routes,
        flightType: routeParams.flightType,
      });

      if (aircraftModelId) {
        let filterValues = getFilterValues('byAircraft');
        filterValues.airplanes.modelsList =
          filterValues?.airplanes?.modelsList.map(model => ({
            ...model,
            selected: aircraftModelId == model.id,
          })) ?? [];
        filterValues.helicopters.modelsList =
          filterValues?.airplanes?.modelsList.map(model => ({
            ...model,
            selected: aircraftModelId == model.id,
          })) ?? [];

        Filters.updateFilterValues('byAircraft', filterValues);
        execFiltering();
      }
    }
  }, [searchResults]);

  // Creating Search Header Props
  const searchHeaderProps = {
    ...Helpers.getHeaderPropsForMultipleRoutes(routeParams, path),
    onGetFilterValues: Filters.getFilterValues,
    onUpdateFilterValues: handleSetFilterValue,
    onSortData: execSortering,
    onBackAction: handleBackAction,
    routeParams,
  };

  if (!searchResults.routes) {
    return (
      <SearchLoading
        origin={searchHeaderProps.origin.name}
        destination={searchHeaderProps.destination.name}
      />
    );
  }

  window.scrollTo({ top: 0 });
  if (
    routeParams.flightType === 'flights' &&
    searchFilteredResults.routes?.length
  ) {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
        <SearchResultsHeader {...searchHeaderProps} />
        <SharedFlight
          searchParams={routeParams}
          flights={searchFilteredResults.routes}
        />
      </>
    );
  } else if (routeParams.flightType !== 'flights') {
    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>

        <SearchResultsHeader {...searchHeaderProps} />

        {!searchFilteredResults?.routes?.length || true === 0 ? (
          <h1
            style={{
              marginTop: '160px',
              display: 'grid',
              width: '100%',
              height: '500px',
              placeContent: 'center',
              textAlign: 'center',
            }}
          >
            {t('noResultFound')}
          </h1>
        ) : null}
        {searchFilteredResults.routes &&
        searchFilteredResults.routes.length === 1 ? (
          <SearchResultsAircraft
            data={searchFilteredResults}
            modelId={selectedModelId}
            onSelectAircraft={handleSelectAircraft}
          />
        ) : (
          <>
            {searchFilteredResults.routes &&
              searchFilteredResults.routes.map((route, index) => (
                <FlightResultSection
                  key={index}
                  initiallyOpened={searchFilteredResults.routes.length === 1}
                  originSpot={route.originSpot}
                  destinationSpot={route.destinationSpot}
                  flightsList={route.aircraftModels || [route.aircraft]}
                  mappedRouteIndex={route.mappedIndex}
                  onSelect={selectRoute}
                />
              ))}
          </>
        )}
      </>
    );
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>

      <SearchResultsHeader {...searchHeaderProps} />

      <h1
        style={{
          marginTop: '160px',
          display: 'grid',
          width: '100%',
          height: '500px',
          placeContent: 'center',
          textAlign: 'center',
        }}
      >
        {t('noResultFound')}
      </h1>
    </>
  );
};

export default Search;
