// TODO: REFACTORING
import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Swiper from 'react-id-swiper';
import { useMediaQuery } from 'react-responsive';

import parseSharedFlightData from '@utils/parseSharedFlightData';
import SharedFlightInfo from '@components/SharedFlightInfo/SharedFlightInfo';

import Styles from './style.module.css';
import 'swiper/css/swiper.min.css';
import './swiper.config.css';

function SharedCarousel({ flights, onSelect }) {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const swiper = createRef();
  const [selectedFlight, setSelectedFlight] = useState(null);
  const swiperParams = {
    direction: !isMobile ? 'vertical' : 'horizontal',
    slidesPerView: !isMobile ? 2 : 1,
    slidesPerGroup: 1,
    spaceBetween: !isMobile ? 30 : 0,
    shouldSwiperUpdate: true,
    mousewheel: true,
  };

  useEffect(() => {
    try {
      const mySwiper = swiper.current.swiper;
      setSelectedFlight(flights[0].id);
      mySwiper.on('slideChange', function() {
        setSelectedFlight(flights[mySwiper.activeIndex].id);
      });
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    if (selectedFlight) {
      onSelect(selectedFlight);
    }
  }, [selectedFlight]);

  function createSwaper() {
    return (
      <Swiper {...swiperParams} ref={swiper}>
        {flights.map(flight => (
          <div
            className={!isMobile ? Styles.item : Styles.itemMobile}
            key={flight.id}
          >
            <SharedFlightInfo
              data={parseSharedFlightData(flight)}
              theme={'origin'}
              variant={'carousel'}
            />
          </div>
        ))}
      </Swiper>
    );
  }

  if (isMobile) {
    return (
      <div className={`shared-flights-carousel ${Styles.wrapperMobile}`}>
        {createSwaper()}
      </div>
    );
  }

  return (
    <div className={`shared-flights-carousel ${Styles.wrapper}`}>
      {createSwaper()}
      <div className={Styles.empty} key={'empty'} />
    </div>
  );
}

SharedCarousel.propTypes = {
  flights: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default SharedCarousel;
