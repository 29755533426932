// Third party libs/components
import React, {
  useState,
  Fragment,
  useCallback,
  useRef,
  useEffect,
} from 'react';
import { Link } from 'gatsby';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useParams } from '@reach/router';

// Components
import * as Styled from './SearchResultsHeader.style';
import { DateRange, Calendar } from 'react-date-range';
import LoginModal from '../LoginModal';
import BackButton from '../BackButton';
import MenuModal from '@components/MenuModal';

import SearchFilter from '@components/SearchFilter';
import Trip from './Filters/Trip';
import Passengers from './Filters/Passengers';
import FlightType from './Filters/FlightType';
import Aerodrome from './Filters/Aerodrome';
import Aircraft from './Filters/Aircraft';
import Order from './Filters/Order';
import ConfirmButton from '@components/ConfirmButton';

// Utils
import * as Helpers from './utils/SearchResultsHeader.helper';

// Assets
import StyleDatePicker from './datepicker-style.module.css';
const greenPin = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="24" viewBox="0 0 16.334 24"><g transform="translate(0)"><path fill="#80CBC4" d="M93.5,0a8.094,8.094,0,0,0-8.167,8,7.886,7.886,0,0,0,.98,3.8l6.74,11.94a.516.516,0,0,0,.893,0L100.689,11.8a7.884,7.884,0,0,0,.978-3.8A8.094,8.094,0,0,0,93.5,0Zm0,12a4.047,4.047,0,0,1-4.083-4,4.084,4.084,0,0,1,8.167,0,3.922,3.922,0,0,1-.459,1.842A4.094,4.094,0,0,1,93.5,12Z" transform="translate(-85.333)"/></g></svg>`;
const swapIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 15.172 11.869"><g transform="translate(15.172) rotate(90)"><g transform="translate(0)"><path fill="#fff" d="M3.391,0,0,3.363H2.543V9.272h1.7V3.363H6.782Z"/><path fill="#fff" d="M4.239,5.909V0h-1.7V5.909H0L3.391,9.272,6.782,5.909Z" transform="translate(5.087 5.9)"/></g></g>svg>`;
const menuIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="15" viewBox="0 0 24 9"><path fill="#fff" d="M12 3h-12v-3h24v3zm0 3h-6v3h18v-3zm0"/></svg>`;

// Consts
const localeLangsMap = {
  pt_BR: locales.pt,
  en_US: locales.en,
  es_ES: locales.es,
  // it_IT: locales.it,
};
const datePickerSettings = {
  months: 1,
  direction: 'horizontal',
  editableDateInputs: true,
  moveRangeOnFirstSelection: false,
  showMonthAndYearPickers: false,
  showSelectionPreview: true,
  showDateDisplay: false,
  color: '#00BFA5',
  rangeColors: ['#00BFA5'],
  weekStartsOn: 0,
  monthDisplayFormat: 'LLLL',
  fixedHeight: false,
  focusedRange: [0, 1],
  dragSelectionEnabled: false,
};

const SearchResultsHeader = ({
  translations,
  origin,
  destination,
  departureDate,
  returnDate,
  onGetFilterValues,
  onUpdateFilterValues,
  onSortData,
  onBackAction,
}) => {
  const pageLang = Helpers.useCurrentLang();
  const query = Helpers.headerQuery();
  const routeParams = useParams();
  const [loginOpened, setLogin] = useState(false);
  const [menuOpened, setMenu] = useState(false);
  const [dateOpened, setDateOpened] = useState(false);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [passengersFilterValue, setPassengersFilterValue] = useState(1);
  const [datePickerOpened, setDatePickerOpened] = useState(false);
  const [singleDate, setSingleDate] = useState(departureDate);
  const [newReturnDate, setReturnDate] = useState();
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: departureDate,
      endDate: returnDate || departureDate,
      key: 'selection',
    },
  ]);
  const datePickerRef = useRef(null);

  const oneWayTrip = routeParams.returnDate === '-';
  const flightTypeFilterValue = Helpers.getFlightTypeId(routeParams.flightType);
  const orderOptions = [
    {
      method: 'byPrice',
      variant: 'lower',
      label: Helpers.t('orderLowerPrice'),
      showOn: [2, 4],
    },
    {
      method: 'byPrice',
      variant: 'higher',
      label: Helpers.t('orderHigherPrice'),
      showOn: [2, 4],
    },
    {
      method: 'byDate',
      variant: 'closest',
      label: Helpers.t('orderDate'),
      showOn: [4],
    },
  ];
  const departureDateObj = {
    day: Helpers.dateToDay(departureDate),
    month: Helpers.dateToMonthName(departureDate),
  };
  const returnDateObj = {
    day: returnDate ? Helpers.dateToDay(returnDate) : null,
    month: returnDate ? Helpers.dateToMonthName(returnDate) : null,
  };
  const allHeaderData = {
    pt_BR: query.wordpressAcfOptions.options_pt_BR,
    en_US: query.wordpressAcfOptions.options_en_US,
    es_ES: query.wordpressAcfOptions.options_es_ES,
    // it_IT: query.wordpressAcfOptions.options_it_IT,
  };
  const headerData = allHeaderData[Helpers.useCurrentLang()];
  const queryLanguages = headerData.header_languages;
  const loginContent = headerData.header_login;
  const menuItems = Helpers.mountMenuItems({ query, pageLang });

  const setDateRange = selection => {
    setDateRangeState(selection);
    const { startDate, endDate } = selection[0];

    if (Helpers.dateToString(startDate) === Helpers.dateToString(endDate)) {
      setReturnDate(null);
    } else {
      setReturnDate(endDate);
    }
  };

  const handleSetPassengers = values => {
    setPassengersFilterValue(values.numberOfSeats);
    onUpdateFilterValues('bySeats', values);
  };

  const handleFlightType = type => {
    const newParams = { ...routeParams };

    if (type) newParams.flightType = Helpers.getFlightTypeString(type);

    Helpers.redefineUrlAndReload(newParams);
  };

  const handleTrip = useCallback((isOneWayTrip, departureDate, returnDate) => {
    const newParams = { ...routeParams };
    if (!isOneWayTrip) {
      // add new dates

      if (departureDate)
        newParams.departureDate = Helpers.stringToDate(departureDate);
      if (returnDate) newParams.returnDate = Helpers.stringToDate(returnDate);

      Helpers.redefineUrlAndReload(newParams);
    } else {
      if (departureDate)
        newParams.departureDate = Helpers.stringToDate(departureDate);
      newParams.returnDate = '-';

      Helpers.redefineUrlAndReload(newParams);
    }
  }, []);

  const handleSwapLocations = () => {
    const origin = routeParams.origin;
    const destination = routeParams.destination;
    const newParams = { ...routeParams };

    // Swap segments locations
    newParams.origin = destination;
    newParams.destination = origin;

    // redefineSearchAndReload(newParams, sRParams, sRDispatch);
    Helpers.redefineSearchAndReload({ newParams, routeParams });
  };

  useEffect(() => {
    const values = {
      numberOfSeats:
        parseInt(routeParams.adults) + parseInt(routeParams.children),
    };

    let timerHandleSetPassengers = setTimeout(
      () => handleSetPassengers(values),
      500
    );

    return () => {
      clearTimeout(timerHandleSetPassengers);
    };
  }, []);

  Helpers.useOutsideClick(datePickerRef, () => {
    setDatePickerOpened(false);
  });

  const handleGetBack = () => {
    onBackAction();
  };

  return (
    <Styled.SearchResultsHeaderWrapper menuOpened={menuOpened}>
      <Styled.SearchResultsHeaderContainer className={'container'}>
        <Styled.Top>
          <Styled.BackButtonContainer>
            <BackButton text={Helpers.t('back')} onClick={handleGetBack} />
          </Styled.BackButtonContainer>
          <Styled.LocationsDisplay>
            <Styled.Origin>
              <Styled.OriginIcon />
              <Styled.HideOverflowText>
                <span>
                  <Styled.LocationName>{origin.name} </Styled.LocationName>
                  {origin.code}
                </span>
              </Styled.HideOverflowText>
            </Styled.Origin>
            <Styled.SwapLocations onClick={() => handleSwapLocations()}>
              <Styled.SwapIcon dangerouslySetInnerHTML={{ __html: swapIcon }} />
            </Styled.SwapLocations>
            <Styled.Destination>
              <Styled.DestinationIcon
                dangerouslySetInnerHTML={{ __html: greenPin }}
              />
              <Styled.HideOverflowText>
                <span>
                  <Styled.LocationName>{destination.name} </Styled.LocationName>
                  {destination.code}
                </span>
              </Styled.HideOverflowText>
            </Styled.Destination>
          </Styled.LocationsDisplay>
          <Styled.DateDisplay dateOpened={dateOpened} ref={datePickerRef}>
            <Styled.DateDisplayContentWrapper
              onClick={() => setDatePickerOpened(!datePickerOpened)}
            >
              <Styled.Month>{Helpers.t(departureDateObj.month)}</Styled.Month>
              <Styled.Day>{departureDateObj.day}</Styled.Day>
              {!oneWayTrip && (
                <Fragment>
                  <Styled.ToLabel>{Helpers.t('to')}</Styled.ToLabel>
                  <Styled.Day>{returnDateObj.day}</Styled.Day>
                  {departureDateObj.month !== returnDateObj.month && (
                    <Styled.Month>
                      {Helpers.t(returnDateObj.month)}
                    </Styled.Month>
                  )}
                </Fragment>
              )}
            </Styled.DateDisplayContentWrapper>
            <div
              className={[
                StyleDatePicker.datePicker,
                datePickerOpened ? StyleDatePicker.datePickerOpened : '',
              ].join(' ')}
            >
              {oneWayTrip ? (
                <Calendar
                  date={singleDate}
                  locale={localeLangsMap[pageLang]}
                  onChange={item => setSingleDate(item)}
                  {...datePickerSettings}
                />
              ) : (
                <DateRange
                  ranges={dateRangeState}
                  locale={localeLangsMap[pageLang]}
                  onChange={item => setDateRange([item.selection])}
                  minDate={departureDate}
                  {...datePickerSettings}
                />
              )}
              {(newReturnDate || oneWayTrip) && (
                <ConfirmButton
                  onClick={() => {
                    handleTrip(
                      oneWayTrip,
                      oneWayTrip ? singleDate : dateRangeState[0].startDate,
                      dateRangeState[0].endDate
                    );
                  }}
                />
              )}
            </div>
          </Styled.DateDisplay>
          <Styled.OpenMenuModal
            onClick={() => setMenu(true)}
            dangerouslySetInnerHTML={{ __html: menuIcon }}
          />
        </Styled.Top>
        <Styled.Bottom>
          <Styled.MobileBottom>
            <Styled.ToggleDate
              onClick={() => {
                setDateOpened(!dateOpened);
                setFiltersOpened(false);
              }}
            >
              {Helpers.t('date')}
            </Styled.ToggleDate>
            <Styled.MobileLogoContainer>
              <Link to="/">
                <Styled.MobileLogo
                  dangerouslySetInnerHTML={{
                    __html: headerData.desktop_logo_svg,
                  }}
                />
              </Link>
            </Styled.MobileLogoContainer>
            <Styled.ToggleFilters
              onClick={() => {
                setFiltersOpened(!filtersOpened);
                setDateOpened(false);
              }}
            >
              {Helpers.t('filters')}
            </Styled.ToggleFilters>
          </Styled.MobileBottom>
          {
            <Styled.FiltersContainer opened={filtersOpened}>
              <Styled.LeftFiltersContainer>
                <SearchFilter
                  label={
                    oneWayTrip ? Helpers.t('oneWay') : Helpers.t('roundTrip')
                  }
                  closeOnOutsideClick={false}
                >
                  <Trip
                    handleSetTrip={handleTrip}
                    origin={origin.name}
                    destination={destination.name}
                    departureDate={departureDate}
                    returnDate={returnDate}
                  />
                </SearchFilter>
                <SearchFilter
                  label={`${passengersFilterValue} ${Helpers.t('passengers')}`}
                >
                  <Passengers
                    data={onGetFilterValues('bySeats')}
                    onSubmit={values => handleSetPassengers(values)}
                  />
                </SearchFilter>
                <SearchFilter
                  label={`${
                    flightTypeFilterValue === 4
                      ? Helpers.t('sharedFlight')
                      : Helpers.t('charteredFlight')
                  }`}
                >
                  <FlightType
                    handleSetFlightType={handleFlightType}
                    selectedType={flightTypeFilterValue}
                  />
                </SearchFilter>
              </Styled.LeftFiltersContainer>

              <Styled.LogoWrapper>
                <Link to="/">
                  <Styled.Logo
                    dangerouslySetInnerHTML={{
                      __html: headerData.desktop_logo_svg,
                    }}
                  />
                </Link>
              </Styled.LogoWrapper>

              <Styled.RightFiltersContainer>
                <SearchFilter rightSide label={Helpers.t('aircrafts')}>
                  <Aircraft
                    data={onGetFilterValues('byAircraft')}
                    onSubmit={values =>
                      onUpdateFilterValues('byAircraft', values)
                    }
                  />
                </SearchFilter>
                <SearchFilter rightSide label={Helpers.t('aerodrome')}>
                  <Aerodrome
                    data={onGetFilterValues('byAerodrome')}
                    onSubmit={values =>
                      onUpdateFilterValues('byAerodrome', values)
                    }
                  />
                </SearchFilter>
                <SearchFilter rightSide label={Helpers.t('sort')}>
                  <Order
                    options={orderOptions}
                    onClick={onSortData}
                    context={flightTypeFilterValue}
                  />
                </SearchFilter>
              </Styled.RightFiltersContainer>
            </Styled.FiltersContainer>
          }
        </Styled.Bottom>
        {menuOpened && (
          <MenuModal
            translations={translations}
            queryLanguages={queryLanguages}
            loginContent={loginContent}
            openLogin={() => setLogin(true)}
            menuItems={menuItems}
            closeMenu={() => setMenu(false)}
          />
        )}
        {loginOpened && (
          <LoginModal
            loginImage={loginContent.login_modal_image}
            forgotPasswordMsg={loginContent.forgot_password_msg}
            logo={loginContent.login_modal_logo_svg}
            opened={loginOpened}
            closeModal={() => setLogin(false)}
          />
        )}
      </Styled.SearchResultsHeaderContainer>
    </Styled.SearchResultsHeaderWrapper>
  );
};

export default SearchResultsHeader;
